import "./styles.scss"

import React from "react"

const Main = ({ data }) => {
  return (
    <section className="privacy-policy-main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div
              dangerouslySetInnerHTML={{ __html: data?.privacyPolicyContent }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
